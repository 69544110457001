<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="empresas"
          class="row-pointer transparent"
          disable-pagination
          @click:row="(item) => handleClick(item)"
          :search="search"
          :loading="loading"
          dark
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ title }}</v-toolbar-title>
            </v-toolbar>
          </template>
           <template v-slot:item.uuid="{ item }">
            <v-icon small :class="item.uuid ? 'green' : 'red'">{{
                  item.uuid ? "mdi-check" : "mdi-close"
                }}</v-icon>
          </template>
         

          <template v-slot:no-data>
            <v-alert :value="true" color="info" icon="mdi-information"
              >No hay empresas registrados.</v-alert
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "empresas-list",
  props: {
    search: {
      type: String,
    },
    title: {
      type: String,
      default: "Lista de empresas",
    },
    empresas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "uuid", value: "uuid" },

        { text: "Razón social", value: "razonSocial" },
        { text: "Nombre fantasía", value: "nombreFantasia" },

        { text: "E-mail", value: "email", sortable: false },
        { text: "Dirección", value: "direccion", sortable: false },
        { text: "Localidad", value: "localidad.nombre", sortable: false },
        {
          text: "Provincia",
          value: "localidad.provincia.nombre",
          sortable: false,
        },
      ],
      loading: false,
    };
  },
  methods: {
    handleClick(empresa) {
      this.$router.push({
        name: "empresaDatosGenerales",
        params: { uuid_empresa: empresa.uuid, empresa: empresa },
      });
    },
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
