<template>
  <LayoutBasic :showLinksMenu="false">
    <!-- <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5":items="navigation" divider=">">
          <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{ item.text }}</v-breadcrumbs-item>
        </v-breadcrumbs>
      </template> -->
    <template v-slot:navigation-title> Empresas </template>
    <template v-slot:navigation-content>
      <v-list-item>
        <v-list-item-title>
          <v-text-field
            rounded
            outlined
            style="background-color: #3a3b3c"
            dense
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            placeholder="Buscar empresa"
            single-line
            hide-details
            autofocus
          ></v-text-field>
        </v-list-item-title>
      </v-list-item>
       <v-list-item @click="$router.push({ name: 'empresasList' })">
        <v-list-item-icon>
          <v-icon>mdi-menu</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Lista de empresas</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$router.push({ name: 'registrarEmpresa' })">
        <v-list-item-icon>
          <v-icon>mdi-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Nuevo empresa</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$router.push({ name: 'configuracionsistema' })">
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Configuracion</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:navigation-controller> </template>
    <template v-slot:vista-previa>


      <router-view :empresas="empresas" :search="search"></router-view>

    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";
import ProveedorCreate from "@/components/proveedor/ProveedorCreate";
import ClienteUpdate from "@/views/cliente/ClienteUpdate";
import { mapGetters } from "vuex";
import EmpresasList from "@/components/empresa/EmpresasList.vue";

export default {
  name: "proveedores-home",
  components: {
    LayoutBasic,
    ProveedorCreate,
    ClienteUpdate,
    EmpresasList,
  },
  data() {
    return {
      search: "",
      dialogProveedorCreate: false,
      empresas: [],
    };
  },
  computed: {
    ...mapGetters("proveedor", ["proveedores"]),
  },
  methods: {
    selectEmpresa(empresa) {
      alert("Empresa seleccionada", empresa);
      //   this.$router.push({ path: `/proveedores/${proveedor.id}` });
    },
    fetchEmpresas() {
      this.axios
        .get("/empresas")
        .then((response) => {
          this.empresas = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  created() {
    this.fetchEmpresas();
  },
};
</script>

<style lang="scss" scoped></style>
